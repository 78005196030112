<template>
  <Website msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Website from './components/index.vue'

export default {
  name: 'App',
  components: {
    Website
  }
}
</script>

<style>
#app,body,html {
  padding: 0;
  margin: 0;
}
</style>
