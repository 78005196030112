<template>
  <div class="warp">
   <div class="box">
   <img src="@/assets/bg.png" class="bg">
   <div class="btn-warp" @click="down">
    <img src="@/assets/icon-down.png" alt="">
    <span>下载体验</span>
   </div>
   </div>
   <div class="footer">
    <span v-for="(item,index) in footerList" :key="index">{{ item }}</span>
   </div>
  </div>
</template>

<script>
export default {
  name: 'Website-components',
  data(){
   return {
    footerList:['广州市云兰霞衣科技有限公司','版权所有 ICP 备案','粤ICP备2021025534号','邮编: 518000联系人: 唐先生联系']
   }
  },
  methods:{
   down(){
    window.open('https://apps.apple.com/cn/app/id6446105966')
   }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.warp{
 width: 100%;
 height: 100%;
}
.box{

 width: 100%;
 position: relative;
}
.bg{
 width: 100%;
 margin-bottom: 0;
 min-height: 100vh;
}
.footer{
 height: 60px;
 background: #253347;
 color: #FFFFFF;
 font-size: 24px;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 gap: 36px;
 margin-top: -10px;
}
.btn-warp{
 cursor: pointer;
 width: 250px;
height: 72px;
background: #FFC600;
border-radius: 34px;
display: flex;
justify-content: center;
align-items: center;
color: #222222;
font-size: 28px;
position: absolute;
z-index: 99;
left: 10%;
top: 50%;
}
.btn-warp img{
 width: 44px;
}
</style>
